@import './assets/style/variable.scss';

.app {
  max-width: 768px;
  margin: auto;
  header {
    box-shadow: 0 0 1rem rgba(19, 16, 21, 0.2);
    .logo {
      width: 2.5rem;
    }
  }
  .level-text {
    position: absolute;
    top: 52%;
    left: 45%;
    transform: translate(-50%, -50%);
    text-shadow: 0.1rem 0.1rem 0 $common;
  }
  .text-box {
    width: 3.4rem;
    margin: 0 0.4rem;
    text-align: center;
  }
  .text-hidden {
    opacity: 0;
  }
  .time-box {
    width: 3.4rem;
    height: 2.6rem;
    line-height: 2.6rem;
    background-color: $primary;
    text-align: center;
    border-radius: 0.5rem 0.5rem 0 0;
    font-size: 1.4rem;
    margin: 0 0.4rem;
    box-shadow: 0 0 1rem rgba(229, 191, 255, 0.2);
  }
}