$color-opacity-1: rgba(255, 255, 255, 0.1);
$color-opacity-2: rgba(255, 255, 255, 0.2);
$white: #ffffff;
$black: #13132d;
$dark: #1d1d36;
$gray: #d2d2d6;
$common: #2D2D4E;
$primary: #8153FF;
$primary1: #7f87fc;
$success: #10CE52;
$success1: #42d877;
$warning: #FFAA27;
$danger: #ED1C49;
